import { z } from 'zod'
import { setTime } from '~/utils/strings.ts'

export const hoursRegEx = /^(?:[01]\d|2[0-3]):[0-5]\d|24:00$/

export const timeIntervalSchema = z
  .object({
    startTime: z.string().regex(hoursRegEx),
    endTime: z.string().regex(hoursRegEx),
  })
  .superRefine(({ startTime, endTime }, ctx) => {
    if (setTime(new Date(), startTime) > setTime(new Date(), endTime)) {
      ctx.addIssue({
        path: ['startTime'],
        code: 'custom',
        message: 'Acaba antes',
      })
      return ctx.addIssue({
        path: ['endTime'],
        code: 'custom',
        message: 'de empezar',
      })
    }
  })

export const dateOverrideSchema = z.object({
  date: z.string(),
  startTime: z.string().regex(hoursRegEx),
  endTime: z.string().regex(hoursRegEx),
  canceledDay: z.boolean().optional(),
})
